<template>
    <nav class="navbar fixed-top navbar-expand-lg" :class="{'shadow': platform == 'mobile'}">
        <div class="w-100">
            <template v-if="platform == 'desktop'">
                <div class="row">
                    <div class="col">
                        <ul class="navbar-nav no-border">
                            <li class="nav-item" :class="{'active': page_current == ''}">
                                <router-link to="/" class="nav-link text-uppercase notranslate">
                                    <i class="fad fa-chart-network mr-2"></i>Esportes
                                </router-link>
                            </li>
                            <template v-if="isLogged">
                                <template v-if="pipe == 1">
                                    <li class="nav-item" :class="{'active': page_current == 'table'}">
                                        <router-link to="/table" class="nav-link text-uppercase">
                                            <i class="fas fa-print mr-2"></i>Tabela
                                        </router-link>
                                    </li>
                                    <li class="nav-item" :class="{'active': page_current == 'bets'}">
                                        <router-link to="/bets" class="nav-link text-uppercase">
                                            <i class="fas fa-receipt mr-2"></i>Apostas
                                        </router-link>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link text-uppercase" @click="prebet = true" href="javascript:;" v-if="page_current == ''">
                                            <i class="far fa-check-double mr-2"></i>Pré-aposta
                                        </a>
                                    </li>
                                </template>
                                <template v-else>
                                    <li class="nav-item" :class="{'active': page_current == 'cassino'}">
                                        <router-link to="/cassino" class="nav-link text-uppercase">
                                            <i class="fas fa-dice mr-2"></i>Cassino
                                        </router-link>
                                    </li>
                                    <li class="nav-item" :class="{'active': page_current == 'bets'}">
                                        <router-link to="/bets" class="nav-link text-uppercase">
                                            <i class="fas fa-receipt mr-2"></i>Apostas
                                        </router-link>
                                    </li>
                                </template>
                            </template>
                            <template v-else>
                                <li class="nav-item" v-if="cassino == 'sim'" :class="{'active': page_current == 'cassino'}">
                                    <router-link to="/cassino" class="nav-link text-uppercase">
                                        <i class="fas fa-dice mr-2"></i>Cassino
                                    </router-link>
                                </li>
                                <li class="nav-item" v-if="page_current != 'cassino' && page_current != 'store'">
                                    <a class="nav-link text-uppercase" @click="ticket = true"  href="javascript:;">
                                        <i class="fas fa-receipt mr-2"></i>Bilhete
                                    </a>
                                </li>
                                <li class="nav-item" v-if="loja == 'sim'" :class="{'active': page_current == 'store'}">
                                    <router-link to="/store" class="nav-link text-uppercase">
                                        <i class="fas fa-shopping-cart mr-2"></i>Loja
                                    </router-link>
                                </li>
                            </template>
                        </ul>
                    </div>
                    <div class="col-auto">
                        <a class="navbar-brand mr-0" href="/">
                            <img class="align-top" :src="logo" height="30" alt="logo">
                        </a>
                    </div>
                    <div class="col">
                        <div class="d-flex align-items-center justify-content-end h-100">
                            <div class="mr-2">
                                <!-- <div class="btn-group">
                                    <button class="btn btn-sm dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <div class="country-flag-24-2x d-inline-block align-middle" :class="{'is-br': lang == 'pt', 'is-us': lang == 'en', 'is-es': lang == 'es'}"></div>
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-right">
                                        <a class="dropdown-item" href="javascript:;" v-for="(item, index) in langs" :key="index" @click="changeLanguage(item.lang)">
                                            <div class="country-flag-24-2x d-inline-block align-top" :class="{'is-br': item.lang == 'pt', 'is-us': item.lang == 'en', 'is-es': item.lang == 'es'}"></div>
                                            <div class="d-inline-block ml-2">{{ item.label }}</div>
                                        </a>
                                    </div>
                                </div> -->
                                <div class="country-flag-24-2x d-inline-block align-top is-br"></div>
                                <!-- <img src="https://cdn.countryflags.com/thumbs/brazil/flag-round-250.png" height="22" alt="Brasil"> -->
                            </div>
                            <template v-if="isLogged">
                                <div class="btn-group">
                                    <button class="btn btn-sm bg-button dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="fas fa-user-circle mr-2"></i>Minha conta
                                    </button>
                                    <div class="dropdown-menu dropdown-menu-right min-width">
                                        <h6 class="dropdown-header">
                                            <div class="text-truncate" style="max-width: 120px;">Olá, {{ user_name }}</div>
                                            <div class="dropdown-divider"></div>
                                            <div class="row">
                                                <div class="col-auto">
                                                    <span class="badge badge-info" @click="copyClipboard(ref_id)" role="button">COPIAR PIN</span>
                                                </div>
                                                <div class="col text-right text-info text-uppercase">{{ ref_id }}</div>
                                            </div>
                                        </h6>
                                        <div class="dropdown-divider"></div>
                                        <div class="dropdown-header">
                                            <template v-if="inGame">
                                                <div class="alert alert-warning text-center" role="alert">
                                                    <div class="d-flex flex-column align-items-center justify-content-center">
                                                        <span>
                                                            <i class="fas fa-dice"></i>
                                                        </span>
                                                        <span>Em jogo.</span>
                                                    </div>
                                                </div>
                                            </template>
                                            <template v-else>
                                                <template v-if="pipe == 4">
                                                    <div class="d-flex flex-column">
                                                        <div class="mb-2">
                                                            <a class="btn btn-block text-uppercase" role="button" href="/account?nav=deposit">Depositar</a>
                                                        </div>
                                                        <div class="d-flex justify-content-between">
                                                            <div>Saldo</div>
                                                            <div>{{ current_credit }}</div>
                                                        </div>
                                                        <div class="d-flex justify-content-between">
                                                            <div>Bônus</div>
                                                            <div>{{ current_bonus }}</div>
                                                        </div>
                                                    </div>
                                                </template>
                                                <template v-else>
                                                    <div class="d-flex flex-column">
                                                        <div class="d-flex justify-content-between">
                                                            <div>Saldo</div>
                                                            <div>{{ current_credit }}</div>
                                                        </div>
                                                        <div class="d-flex justify-content-between">
                                                            <div>Saldo simples</div>
                                                            <div>{{ current_credit_simple }}</div>
                                                        </div>
                                                    </div>
                                                </template>
                                            </template>
                                        </div>
                                        <div class="dropdown-divider"></div>
                                        <template v-if="pipe == 4">
                                            <router-link to="/account" class="dropdown-item" :class="{'active': page_current == 'account'}">
                                                <i class="fas fa-user mr-2"></i>Minha conta
                                            </router-link>
                                        </template>
                                        <template v-else>
                                            <router-link to="/finance" class="dropdown-item" :class="{'active': page_current == 'finance'}">
                                                <i class="fas fa-coins mr-2"></i>Caixa
                                            </router-link>
                                        </template>
                                        <a class="dropdown-item" href="javascript:;" @click="logout"><i class="fas fa-sign-out-alt mr-2"></i>Sair</a>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <button class="btn btn-sm bg-button ml-2 mr-2 text-uppercase" @click="$router.push('/register')" v-if="cadastro == 1">Registre-se</button>
                                <button class="btn btn-sm bg-button-none text-uppercase" @click="$router.push('/login')">Entrar</button>
                            </template>
                        </div><!-- /d-flex -->
                    </div><!-- /col -->
                </div><!-- /row -->
            </template>
            <template v-else>
                <div class="row">
                    <div class="col">
                        <template v-if="page_current == ''">
                            <div class="btn-icon" @click="sidebar = true">
                                <i class="fas fa-bars"></i>
                            </div>
                        </template>
                        <template v-else>
                            <div class="btn-icon" @click="pageHome">
                                <i class="fas fa-home"></i>
                            </div>
                        </template>
                    </div>
                    <div class="col-auto">
                        <a class="navbar-brand mr-0" href="/">
                            <img class="align-top" :src="logo" height="30" alt="logo">
                        </a>
                    </div>
                    <div class="col text-right">
                        <template v-if="isLogged">
                            <div class="dropdown">
                                <div class="btn-icon dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="fas fa-user-circle"></i>
                                </div>
                                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                    <h6 class="dropdown-header">
                                        <div class="text-truncate" style="max-width: 120px;">Olá, {{ user_name }}</div>
                                        <div class="dropdown-divider"></div>
                                        <div class="row" @click="copyClipboard(ref_id)">
                                            <div class="col text-center text-info text-uppercase">{{ ref_id }}</div>
                                            <div class="w-100"></div>
                                            <div class="col text-center">
                                                <span class="badge badge-info">COPIAR PIN</span>
                                            </div>
                                        </div>
                                    </h6>
                                    <div class="dropdown-divider"></div>
                                    <div class="dropdown-header">
                                        <i class="far fa-dollar-sign mr-2"></i>
                                        <div class="float-right">{{ current_credit }}</div>
                                        <template v-if="pipe == 1">
                                            <div class="dropdown-divider"></div>
                                            <i class="far fa-dollar-sign mr-2"></i>
                                            <div class="float-right">{{ current_credit_simple }}</div>
                                        </template>
                                    </div>
                                    <div class="dropdown-divider"></div>
                                    <template v-if="pipe == 4">
                                        <a class="dropdown-item" href="javascript:;" @click="$router.push('/account')" :class="{'active': page_current == 'account'}"><i class="fas fa-user mr-2"></i>Minha conta</a>
                                    </template>
                                    <template v-else>
                                        <a class="dropdown-item" href="javascript:;" @click="$router.push('/finance')" :class="{'active': page_current == 'finance'}"><i class="fas fa-coins mr-2"></i>Caixa</a>
                                    </template>
                                    <a class="dropdown-item" href="javascript:;" @click="logout"><i class="fas fa-sign-out-alt mr-2"></i>Sair</a>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <div class="btn-icon" @click="$router.push('/login')">
                                <i class="fas fa-sign-in-alt"></i>
                            </div>
                        </template>
                    </div>
                </div>
            </template>
        </div><!-- /w-100 -->
    </nav>
</template>

<script>
import Vue from 'vue'
import 'v-slim-dialog/dist/v-slim-dialog.css'
import SlimDialog from 'v-slim-dialog'

Vue.use(SlimDialog)

export default {
    data() {
        return {
            langs: [{
                lang: 'pt',
                label: 'Português',
                image: 'https://cdn.countryflags.com/thumbs/brazil/flag-round-250.png'
            },{
                lang: 'en',
                label: 'English',
                image: 'https://cdn.countryflags.com/thumbs/united-states-of-america/flag-round-250.png'
            },{
                lang: 'es',
                label: 'Spanish',
                image: 'https://cdn.countryflags.com/thumbs/spain/flag-round-250.png'
            }],
            lang: 'pt',
            pipe: 0,
            user_name: '',
            isLogged: false,
            page_current: document.location.pathname.split('/').pop(),
            platform: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent) ? 'mobile' : 'desktop'
        }
    },
    methods: {
        pageHome() {
            this.$router.push('/');
        },
        support() {
            window.location.href = this.service_link;
        },
        logout() {
            this.$dialogs.confirm(`<b>${this.user_name}</b> você deseja realmente sair da sua conta?`, {
                title: 'Atenção!',
                cancelLabel: 'Não',
                okLabel: 'Sim',
                size: 'sm'
            }).then(res => {
                if (res.ok) {
                    this.removeItem('x-token').then(() => {
                        this.removeItem('auth').then(() => {
                            this.removeItem('firebase:pipe').then(() => {
                                window.location.href = '/';
                            });
                        });
                    });
                }
            });
        },
        removeItem(key) {
            return Promise.resolve().then(() => {
                window.localStorage.removeItem(key)
            });
        },
        copyClipboard(text) {
            navigator.clipboard.writeText(text).then(() => {
                this.showAlert(
                    'Parabéns!', 
                    'O <strong>PIN</strong> foi copiado com sucesso!'
                );
            }, () => {
                this.showAlert(
                    'Erro!', 
                    'Não foi possível copiar o <strong>PIN</strong>!'
                );
            });
        },
        showAlert(title, message) {
            this.$dialogs.alert(message, {
                title, 
                size: 'sm'
            });
        },
    },
    created() {
        
        try {
            const {logged, name, pipe} = JSON.parse(window.localStorage.getItem('auth'));

            this.pipe = pipe;
            this.user_name = name;
            this.isLogged = logged;
        } catch(e) {
            this.isLogged = false;
        }
    
        window.addEventListener('resize', () => {
            this.platform = this.isMobile();
        });
    }
}
</script>

<style scoped>
.home-main .navbar {
    background-color: var(--bg-navbar);
    /* border-top: 1px solid var(--theme); */
}
.home-main .navbar .row .col .btn-icon {
    display: inline-block;
    padding-top: .3125rem;
    padding-bottom: .3125rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap;
    color: var(--theme);
}
.home-main .navbar .row .col.text-right .dropdown .dropdown-toggle::after {
    display: none;
}
.btn.btn-sm.dropdown-toggle:not(.bg-button) {
    color: var(--sport-menu-link);
    background: none!important;
    box-shadow: none!important;
    outline: none!important;
}
.dropdown-item.active, .dropdown-item:active {
    color: var(--theme-text);
    background-color: var(--theme);
}
.dropdown-menu.min-width {
    min-width: 250px;
}
a.btn.btn-block {
    color: var(--theme-text)!important;
    background-color: var(--theme)!important;
}
</style>